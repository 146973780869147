@charset "UTF-8";
html {
  background-image: linear-gradient(#cccccc, #fff);
  background-repeat: repeat-x;
  background-size: 100%; }
  html.page-header-full-width {
    background: white; }
    html.page-header-full-width #page {
      box-shadow: none;
      padding-top: 0; }
    html.page-header-full-width .header-image-overlay--right .header-image-overlay__content {
      right: 30px; }

body {
  color: #222;
  background: transparent;
  overflow: hidden; }

img {
  width: auto;
  height: auto; }

.page {
  box-shadow: 0 0 200px #ccc;
  background: #fff;
  margin: 0 auto; }

.page-header {
  background-color: #fff;
  text-align: center; }
  @media (min-width: 992px) {
    .page-header > .inner {
      padding: 30px 0;
      display: flex;
      justify-content: space-between; } }

.page-footer {
  border-top: 1px solid #cccccc;
  padding-top: 30px;
  margin-top: 30px; }

.branding {
  max-width: 500px;
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem; }
  @media (min-width: 992px) {
    .branding {
      padding-top: 0;
      padding-bottom: 0; } }

.page-logo {
  padding: 0;
  text-align: center; }
  .page-logo a {
    display: block; }
  .page-logo img {
    vertical-align: bottom; }

.page-name {
  margin: 0; }

.page-slogan {
  margin: 0; }

*,
*::before,
*::after {
  transition-property: color, background-color, border-color;
  transition-duration: 300ms; }

@keyframes pulsating-glow {
  0% {
    box-shadow: 0 0 3px #d9534f; }
  50% {
    box-shadow: 0 0 10px #d9534f; }
  100% {
    box-shadow: 0 0 3px #d9534f; } }

@keyframes dragging-complete {
  0% {
    background: #5bc0de; }
  100% {
    background: #fff; } }

@keyframes swipe-in {
  0% {
    transform: translate(-150px, 0); }
  100% {
    transform: translate(0, 0); } }

.region-header {
  margin-bottom: 1rem; }
  .region-header .menu {
    width: auto; }
    .region-header .menu::after {
      clear: both;
      content: " ";
      display: table;
      visibility: hidden; }
    .region-header .menu > li {
      display: block;
      float: left; }
    .region-header .menu li {
      float: none;
      display: inline-block;
      margin-right: 15px; }
      .region-header .menu li:last-child {
        margin-right: 0; }
      .region-header .menu li a {
        padding: 2px 0; }
  .region-header .block-search {
    margin-bottom: 0;
    margin-top: 15px; }
    @media (min-width: 768px) {
      .region-header .block-search {
        float: right; } }

.region-header-slide {
  margin-bottom: 1em;
  border-bottom: 1px solid #cccccc;
  clear: both;
  margin-left: -18px;
  margin-right: -18px; }
  @media (min-width: 992px) {
    .region-header-slide {
      margin-left: -36px;
      margin-right: -36px; } }

.region-header-slide-full {
  margin-bottom: 1em;
  border-bottom: 1px solid #cccccc; }
  .region-header-slide-full img {
    max-width: none !important;
    width: 100% !important; }

.navigation-bar-wrapper {
  clear: both;
  background: #26509c;
  margin-left: -35px;
  margin-right: -35px;
  padding-left: 35px;
  padding-right: 35px; }
  .navigation-bar-wrapper > .region-inner {
    max-width: 1100px;
    margin: 0 auto; }

.flexbox .region-navigation-bar > .region-inner::after,
.flexboxlegacy .region-navigation-bar > .region-inner::after {
  display: none; }

.region-help .block {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  position: relative;
  font-size: 12px;
  padding-left: 60px;
  line-height: 1.1em;
  border-color: #39b3d7;
  text-shadow: 1px 1px 1px #39b3d7;
  background-color: #5bc0de;
  color: #fff;
  cursor: help; }
  .region-help .block::before {
    font-family: "iconfont";
    content: ""; }
  .region-help .block::before {
    text-shadow: none;
    font-family: "iconfont";
    color: #39b3d7;
    background-color: #fff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    position: absolute;
    left: 15px;
    top: 13px;
    font-size: 22px; }
  .region-help .block:before, .region-help .block:after {
    display: table;
    content: " "; }
  .region-help .block:after {
    clear: both; }

.region-sidebar-second .block-title {
  background: white;
  color: #888888;
  border-radius: 5px 5px 0 0;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #cccccc;
  margin-bottom: 15px; }
  .region-sidebar-second .block-title + .block-content {
    border-radius: 0 0 5px;
    border-width: 0 1px 1px 1px;
    margin-top: -15px; }

.region-sidebar-second .block-content {
  border-width: 1px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 5px;
  padding: 15px; }
  .region-sidebar-second .block-content::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }

.region-sidebar-second .block {
  margin-bottom: 30px; }
  .region-sidebar-second .block:hover .block-title {
    background-color: white; }

.region-sidebar-second .owl-item > * {
  width: 99%; }

.region-sidebar-second .node-angebot .field-name-field-subtitle,
.region-sidebar-second .node-angebot .field-name-body {
  font-size: 12px; }

.node-angebot.card > .thumbnail {
  margin-bottom: 0; }

.region-bottom {
  border-top: 1px solid #cccccc;
  padding-top: 6em;
  margin-top: 30px; }

.region-footer > .region-inner {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 992px) {
    .region-footer > .region-inner {
      flex-direction: row; } }
  .region-footer > .region-inner::before, .region-footer > .region-inner::after {
    display: none !important; }

.region-footer .menu {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .region-footer .menu {
      flex-direction: row; } }

.block-title {
  margin-top: 0; }

.block-content p:last-child {
  margin-bottom: 0; }

.panel-pane {
  margin-bottom: 15px; }
  .panel-pane .pane-title {
    margin-top: 0; }
  .panel-pane .pane-content p:last-child {
    margin-bottom: 0; }

.block--right,
.panel-pane--right {
  margin-left: auto;
  margin-right: 3px;
  float: right; }

.block-shariff-shariff-block {
  margin-top: 1rem; }

.region-sidebar-first .orientation-horizontal,
.region-sidebar-second .orientation-horizontal {
  flex-direction: column;
  flex-wrap: nowrap; }

.region-sidebar-first .shariff-button,
.region-sidebar-second .shariff-button {
  max-width: none !important;
  min-width: 0 !important;
  flex: 1 0 100%;
  margin-right: 0 !important; }
  .region-sidebar-first .shariff-button:last-child,
  .region-sidebar-second .shariff-button:last-child {
    margin-bottom: 0; }

.views-field-field-headerimage {
  line-height: 0; }

.block-views-header-slideshow-block {
  line-height: 0;
  display: none; }
  @media (min-width: 768px) {
    .block-views-header-slideshow-block {
      display: block; } }

.view-header-slideshow {
  position: relative; }
  .view-header-slideshow .node {
    margin-bottom: 0; }
  .view-header-slideshow div[class*="item-"] {
    display: none; }
  .view-header-slideshow div.item-0 {
    display: block; }
  .view-header-slideshow .owl-item div[class*="item-"] {
    display: block; }
  .view-header-slideshow .view-empty {
    padding: 0 !important;
    background: none !important;
    border: 0 none;
    border-radius: 0; }
  .view-header-slideshow .node-header-image {
    display: flex;
    flex-direction: column; }
    .view-header-slideshow .node-header-image > .field-name-field-headerimage {
      flex: 0 0 auto; }
    .view-header-slideshow .node-header-image > .header-image-overlay {
      flex: 1 0 auto; }

@media (min-width: 992px) {
  .header-image-overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border: 0 none; } }

.header-image-overlay__content {
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px 15px; }
  @media (min-width: 992px) {
    .header-image-overlay__content {
      position: absolute;
      bottom: 15px;
      max-width: 400px; } }
  .header-image-overlay__content p:empty {
    display: none; }
  .header-image-overlay__content h2,
  .header-image-overlay__content h3,
  .header-image-overlay__content h4 {
    margin-top: 0;
    margin-bottom: 1rem; }
  .header-image-overlay__content .field-name-body {
    margin-bottom: 0.5rem !important; }

.header-image-overlay__title {
  margin-top: 0;
  margin-bottom: 15px; }

.header-image-overlay--left .header-image-overlay__content {
  left: 15px; }

.header-image-overlay--right .header-image-overlay__content {
  right: 15px; }

.header-image__overlay-link {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-indent: -9000px; }

.header-image--matching-images {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  border-color: #cccccc;
  border-radius: 8px;
  background-color: #fff; }
  .header-image--matching-images:before, .header-image--matching-images:after {
    display: table;
    content: " "; }
  .header-image--matching-images:after {
    clear: both; }

.field-label {
  font-size: 1em;
  font-weight: 700;
  font-family: inherit;
  line-height: inherit;
  margin-bottom: 0; }

.field-label-inline {
  display: flex; }
  .field-label-inline::before, .field-label-inline::after {
    display: none; }
  .field-label-inline .field-label,
  .field-label-inline .field-items,
  .field-label-inline .field-item {
    display: inline; }

.field-name-body::after {
  clear: both;
  content: " ";
  display: table;
  visibility: hidden; }

.field-name-body h2:first-child,
.field-name-body h3:first-child {
  margin-top: 0;
  padding-top: 0; }

.field-type-image .colorbox {
  cursor: zoom-in; }

.field-type-image .field-item {
  line-height: 0;
  text-align: center; }

.field-name-field-titelbild {
  margin-bottom: 30px; }

.field-name-field-bilder,
.field-name-field-galerie {
  margin-top: 30px;
  margin-bottom: 1em; }
  .field-name-field-bilder .field-item,
  .field-name-field-galerie .field-item {
    margin-bottom: 30px; }
    .field-name-field-bilder .field-item a,
    .field-name-field-galerie .field-item a {
      overflow: hidden;
      display: block; }
    .field-name-field-bilder .field-item img,
    .field-name-field-galerie .field-item img {
      transition-property: transform, opacity; }
    .field-name-field-bilder .field-item:hover img,
    .field-name-field-galerie .field-item:hover img {
      transform: scale(1.05); }

.field-type-video-embed-field {
  margin-bottom: 1em; }
  .field-type-video-embed-field .field-item {
    margin-bottom: 30px; }

Fields
.field-name-field-subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1em;
  padding-top: 0.15em; }

.field-name-field-attachments {
  margin-bottom: 1.5em; }
  .field-name-field-attachments .field-items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
  @media (min-width: 768px) {
    .field-name-field-attachments .field-item {
      width: 50%;
      padding: 0 15px; } }
  .field-name-field-attachments .file {
    display: block;
    padding: 4px 0;
    margin-bottom: 3px; }
    .field-name-field-attachments .file:not(:last-child) {
      border-bottom: 1px solid #aaaaaa; }
    .field-name-field-attachments .file a {
      border-width: 1px;
      border-style: solid;
      display: inline-block;
      font-weight: 300;
      cursor: pointer;
      text-align: left;
      text-decoration: none;
      vertical-align: top;
      user-select: none;
      background-color: transparent;
      border-color: #aaaaaa;
      color: #222;
      padding: 15px 20px;
      border-radius: 5px;
      line-height: 18px;
      font-size: 16px;
      line-height: 1.05em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block; }
      .field-name-field-attachments .file a:hover {
        background-color: transparent;
        border-color: #bfbfbf;
        color: #26509c; }
      .field-name-field-attachments .file a:active, .field-name-field-attachments .file a:focus {
        background-color: transparent;
        border-color: #9e9e9e;
        color: #222; }
      .field-name-field-attachments .file a:hover {
        text-decoration: none; }

.field-name-field-custom-tiles {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem; }
  .field-name-field-custom-tiles > .field-items > .field-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem; }
    .field-name-field-custom-tiles > .field-items > .field-item > .field-name-field-image,
    .field-name-field-custom-tiles > .field-items > .field-item > .field-name-field-titelbild {
      margin-bottom: 0.5rem; }
    .field-name-field-custom-tiles > .field-items > .field-item > .field-type-text-long {
      line-height: 1.35; }
      .field-name-field-custom-tiles > .field-items > .field-item > .field-type-text-long h2,
      .field-name-field-custom-tiles > .field-items > .field-item > .field-type-text-long h3,
      .field-name-field-custom-tiles > .field-items > .field-item > .field-type-text-long h4 {
        margin-top: 0;
        margin-bottom: 0.5rem; }
      .field-name-field-custom-tiles > .field-items > .field-item > .field-type-text-long p:empty {
        display: none; }
    .field-name-field-custom-tiles > .field-items > .field-item > .btn-inside {
      padding-top: 1rem;
      margin-top: auto; }

.field-name-field-product-cc-anchor {
  margin-bottom: 0.5rem; }

.custom-search-filter ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.custom-search-filter li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block; }

.custom-search-filter a {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #aaaaaa;
  color: #222;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  .custom-search-filter a:hover {
    background-color: transparent;
    border-color: #bfbfbf;
    color: #26509c; }
  .custom-search-filter a:active, .custom-search-filter a:focus {
    background-color: transparent;
    border-color: #9e9e9e;
    color: #222; }
  .custom-search-filter a:hover {
    text-decoration: none; }

.node {
  margin-bottom: 20px; }
  .node__header {
    text-align: center; }
    .node__header .page-title {
      display: inline-block;
      line-height: 1.35; }
    .node__header .field-name-field-subtitle {
      display: inline-block;
      font-size: 28px;
      line-height: 1.35;
      margin-left: 15px; }
    .node__header--has-subline {
      margin-bottom: 19px; }
  .node .node-title {
    margin: 0; }
  .node.view-mode-full .field-name-body {
    margin-bottom: 30px; }

footer.submitted {
  background: none repeat scroll 0 0 #cccccc;
  clear: both;
  color: #aaaaaa;
  margin-top: 1em;
  padding: 10px 10px 10px 15px;
  vertical-align: top; }
  footer.submitted .user-picture {
    border: 5px solid #cccccc;
    background: #fff;
    float: left;
    line-height: 0;
    margin-right: 15px;
    margin-left: -15px;
    margin-top: -10px; }

.angebot-content__right,
.product-content__right {
  text-align: left; }
  .angebot-content__right .field-name-field-produkt-tags,
  .product-content__right .field-name-field-produkt-tags {
    padding-top: 0.5rem; }

.angebot-content > .subline {
  margin-top: -18.76px; }

.group-product-techndaten {
  margin-bottom: 1.5rem; }
  .group-product-techndaten > .fieldset-wrapper > .field:not(.field-name-field-product-technd-custom) {
    margin-bottom: 0;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 2px; }
    @media (min-width: 768px) {
      .group-product-techndaten > .fieldset-wrapper > .field:not(.field-name-field-product-technd-custom) {
        display: flex; } }
    .group-product-techndaten > .fieldset-wrapper > .field:not(.field-name-field-product-technd-custom):last-child {
      border-bottom: 0; }
    .group-product-techndaten > .fieldset-wrapper > .field:not(.field-name-field-product-technd-custom) .field-label {
      float: none; }
      @media (min-width: 768px) {
        .group-product-techndaten > .fieldset-wrapper > .field:not(.field-name-field-product-technd-custom) .field-label {
          width: 35%; } }
    .group-product-techndaten > .fieldset-wrapper > .field:not(.field-name-field-product-technd-custom) .field-items {
      float: none; }
      @media (min-width: 768px) {
        .group-product-techndaten > .fieldset-wrapper > .field:not(.field-name-field-product-technd-custom) .field-items {
          width: 65%; } }

.field-name-field-product-technd-custom > .field-items > .field-item {
  margin-bottom: 0;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 2px; }
  @media (min-width: 768px) {
    .field-name-field-product-technd-custom > .field-items > .field-item {
      display: flex; } }
  .field-name-field-product-technd-custom > .field-items > .field-item:last-child {
    border-bottom: 0; }
  .field-name-field-product-technd-custom > .field-items > .field-item .field-name-field-titel-bezeichnung {
    float: none;
    font-weight: 600; }
    @media (min-width: 768px) {
      .field-name-field-product-technd-custom > .field-items > .field-item .field-name-field-titel-bezeichnung {
        width: 35%; } }
    .field-name-field-product-technd-custom > .field-items > .field-item .field-name-field-titel-bezeichnung .field-item::after {
      content: ":"; }
  .field-name-field-product-technd-custom > .field-items > .field-item .field-name-field-text-value {
    float: none; }
    @media (min-width: 768px) {
      .field-name-field-product-technd-custom > .field-items > .field-item .field-name-field-text-value {
        width: 65%; } }
    .field-name-field-product-technd-custom > .field-items > .field-item .field-name-field-text-value ul {
      padding-left: 15px; }

.contact-container .item-content {
  line-height: 20px;
  vertical-align: middle; }

.contact-container .item {
  display: block;
  margin-bottom: 0.4em; }

.contact-container br {
  display: none; }

table.sticky-header {
  z-index: 10; }

td.active {
  background-color: #d1d1d1; }

.view-empty {
  border-width: 1px;
  border-style: solid;
  padding: 30px 30px;
  border-color: #cccccc;
  border-radius: 8px;
  background-color: #fff;
  font-size: 0.9em;
  padding: 2em;
  text-align: center; }
  .view-empty:before, .view-empty:after {
    display: table;
    content: " "; }
  .view-empty:after {
    clear: both; }

.view-header {
  margin-bottom: 30px; }

.view-footer {
  margin-top: 30px; }

.attachment-before {
  margin-bottom: 30px; }

.attachment-after {
  margin-top: 30px; }

.views-row {
  margin-bottom: 15px; }
  .views-row::after {
    clear: both;
    content: " ";
    display: table;
    visibility: hidden; }
  .views-row.cutline-bottom:last-child {
    border-bottom: 0 none;
    margin-bottom: 0;
    padding-bottom: 0; }

.view .item-list > ul {
  margin: 0;
  padding: 0; }
  .view .item-list > ul > .views-row {
    list-style: none; }

.view .item-list.row,
.view .item-list > .row {
  margin-left: 0;
  margin-right: 0; }

.view .item-list:not(.row) > ul.row,
.view .item-list.row > ul:not(.row) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 30px; }

.view .item-list .views-row {
  margin: 0;
  width: auto;
  padding: 0; }
  .view .item-list .views-row > .inner {
    height: 100%; }
  .view .item-list .views-row .thumbnail,
  .view .item-list .views-row .card {
    margin: 0;
    height: 100%; }
    .view .item-list .views-row .thumbnail .caption,
    .view .item-list .views-row .card .caption {
      display: flex;
      flex-direction: column; }
      .view .item-list .views-row .thumbnail .caption .read-on,
      .view .item-list .views-row .card .caption .read-on {
        margin-top: auto; }
  .view .item-list .views-row .thumbnail {
    display: flex;
    flex-direction: column; }
    .view .item-list .views-row .thumbnail > .image-wrapper {
      flex: 0 0 auto;
      aspect-ratio: 4/3; }
      .view .item-list .views-row .thumbnail > .image-wrapper img {
        object-fit: contain;
        height: 100%; }
    .view .item-list .views-row .thumbnail > .caption {
      flex: 1 1 auto; }

.views-exposed-form__button-wrapper {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-left: 15px;
  min-width: 100%; }
  .views-exposed-form__button-wrapper > .views-reset-button {
    padding-left: 4px; }

.views-exposed-form .ctools-auto-submit-full-form .views-submit-button {
  display: none; }

.views-exposed-widgets {
  margin-right: -5px; }
  @media (min-width: 768px) {
    .views-exposed-widgets {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      justify-content: space-between; } }
  .views-exposed-widgets::before, .views-exposed-widgets::after {
    display: none !important; }

.views-exposed-widget {
  margin-bottom: 15px;
  margin-right: 5px; }
  .views-exposed-widget:not(.views-submit-button):not(.views-reset-button) {
    flex: 1; }
  .views-exposed-widget.views-widget-filter-keys {
    min-width: 150px; }
  .views-exposed-widget.views-widget-filter-date_filter > label {
    display: none; }
  .views-exposed-widget .date-padding {
    display: block; }
    .views-exposed-widget .date-padding .form-item {
      margin: 0; }
  .views-exposed-widget .shs-select + .shs-select {
    margin-top: 0.25rem; }

.views-submit-button {
  margin-left: auto; }

.view-image {
  text-align: center; }

.more-link,
.read-on {
  text-align: right;
  display: block;
  clear: both; }

.more-link a,
.read-on a,
a.more-link {
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  font-weight: 300;
  cursor: pointer;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
  user-select: none;
  background-color: transparent;
  border-color: #aaaaaa;
  color: #222;
  padding: 15px 20px;
  border-radius: 5px;
  line-height: 18px;
  font-size: 16px;
  line-height: 1.05em; }
  .more-link a:hover,
  .read-on a:hover,
  a.more-link:hover {
    background-color: transparent;
    border-color: #bfbfbf;
    color: #26509c; }
  .more-link a:active, .more-link a:focus,
  .read-on a:active,
  .read-on a:focus,
  a.more-link:active,
  a.more-link:focus {
    background-color: transparent;
    border-color: #9e9e9e;
    color: #222; }
  .more-link a:hover,
  .read-on a:hover,
  a.more-link:hover {
    text-decoration: none; }
  .more-link a::before,
  .read-on a::before,
  a.more-link::before {
    font-family: "iconfont";
    font-weight: normal !important;
    font-style: normal !important;
    margin-right: 4px;
    content: "";
    color: "inherit";
    font-size: 12px; }

a.more-link {
  float: right;
  margin-top: 15px; }

td.read-on a {
  display: block;
  text-align: left;
  margin-bottom: 0.25em; }

.view-suemo-m-marken .owl-item .views-field-field-titelbild a {
  display: block;
  line-height: 100px;
  text-align: center; }

.view-suemo-m-marken .owl-item .views-field-field-titelbild img {
  vertical-align: middle;
  display: inline; }

.view-suemo-m-team .card__title {
  font-size: 1rem; }

.view-id-glossary .views-row {
  clear: both;
  margin-left: 15px; }

.view-id-glossary .attachment {
  margin-bottom: 1.5em;
  color: #aaaaaa; }

.view-id-glossary .views-summary {
  position: relative; }
  .view-id-glossary .views-summary a {
    text-decoration: none;
    padding: 3px 5px;
    display: inline-block;
    min-width: 15px;
    text-align: center; }
  .view-id-glossary .views-summary .count {
    display: none; }
  .view-id-glossary .views-summary:hover .count {
    position: absolute;
    bottom: 17px;
    right: -2px;
    padding: 2px;
    border-radius: 8px;
    font-size: 0.65em;
    line-height: normal;
    display: inline; }
  .view-id-glossary .views-summary:hover a,
  .view-id-glossary .views-summary a.active {
    border-radius: 5px;
    margin-top: -3px;
    background: #26509c;
    color: #fff; }
  .view-id-glossary .views-summary .view-content a {
    display: inline-block; }
    .view-id-glossary .views-summary .view-content a::first-letter {
      font-size: 1.2em;
      color: #26509c; }

#sliding-popup.sliding-popup-bottom {
  background: rgba(0, 0, 0, 0.85) !important;
  color: #fff; }
  #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-tab {
    border-width: 1px;
    border-style: solid;
    display: inline-block;
    font-weight: 300;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    vertical-align: top;
    user-select: none;
    border-color: #26509c;
    background-color: #26509c;
    color: #fff;
    padding: 15px 20px;
    border-radius: 5px;
    line-height: 18px;
    font-size: 16px;
    line-height: 1.05em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.1); }
    #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-tab:hover {
      border-color: #2b5bb1;
      background-color: #2b5bb1;
      color: #fff; }
    #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-tab:active, #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-tab:focus {
      border-color: #214587;
      background-color: #214587;
      color: #fff; }
    #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-tab:hover {
      text-decoration: none; }
  #sliding-popup.sliding-popup-bottom .eu-cookie-withdraw-banner {
    background-color: transparent !important; }
  #sliding-popup.sliding-popup-bottom .popup-content {
    display: flex;
    align-items: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
    @media only screen and (max-width: 700px) {
      #sliding-popup.sliding-popup-bottom .popup-content {
        flex-direction: column; } }
  #sliding-popup.sliding-popup-bottom #popup-text {
    flex-grow: 1;
    max-width: none;
    padding-right: 30px; }
    #sliding-popup.sliding-popup-bottom #popup-text p,
    #sliding-popup.sliding-popup-bottom #popup-text a {
      font-weight: 300 !important;
      color: #fff !important; }
  #sliding-popup.sliding-popup-bottom #popup-buttons {
    display: flex;
    max-width: none;
    margin-bottom: 0; }
    #sliding-popup.sliding-popup-bottom #popup-buttons button {
      background: #26509c !important;
      color: #fff !important;
      border-radius: 0 !important;
      text-shadow: none !important;
      border-color: #26509c !important;
      margin-top: 0;
      box-shadow: none;
      padding: 10px; }
      #sliding-popup.sliding-popup-bottom #popup-buttons button + button {
        margin-left: 8px; }
      #sliding-popup.sliding-popup-bottom #popup-buttons button:not(.agree-button) {
        font-weight: 300; }

#sliding-popup {
  position: fixed;
  z-index: 9999999999;
  left: 0;
  width: 100% !important;
  background: rgba(0, 0, 0, 0.85) !important;
  color: #fff;
  padding: 15px 10px;
  font-size: 14px;
  transition-duration: 100ms;
  transition-property: transform; }
  #sliding-popup .popup-content {
    background: none;
    color: #fff;
    border: 0 none;
    max-width: 1100px;
    margin: 0 auto;
    background: none; }
    #sliding-popup .popup-content__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-flow: row wrap;
      margin-left: -1rem;
      margin-right: -1rem; }
      @media only screen and (min-width: 992px) {
        #sliding-popup .popup-content__inner {
          flex-flow: nowrap; } }
      #sliding-popup .popup-content__inner > * {
        padding-left: 1rem;
        padding-right: 1rem; }
    @media only screen and (min-width: 768px) {
      #sliding-popup .popup-content {
        flex-wrap: nowrap; } }
    #sliding-popup .popup-content::before {
      display: none; }
    #sliding-popup .popup-content #popup-text {
      margin-bottom: 1rem;
      min-width: 300px;
      max-width: 100vw; }
      @media only screen and (min-width: 768px) {
        #sliding-popup .popup-content #popup-text {
          padding-right: 30px;
          margin-bottom: 0; } }
      #sliding-popup .popup-content #popup-text h2,
      #sliding-popup .popup-content #popup-text h3 {
        font-size: 1em;
        margin: 0 0 0.5em 0; }
      #sliding-popup .popup-content #popup-text a {
        color: #fff; }
      #sliding-popup .popup-content #popup-text p {
        font-size: 1em;
        font-weight: 300;
        color: #fff !important;
        display: inline;
        margin: 0; }
      #sliding-popup .popup-content #popup-text .find-more-button {
        background: none;
        border: 0 none;
        padding: 0;
        text-decoration: underline;
        font-size: 1em !important;
        cursor: pointer;
        color: currentColor;
        vertical-align: baseline; }
        #sliding-popup .popup-content #popup-text .find-more-button:hover {
          color: currentColor;
          text-decoration: underline; }
    #sliding-popup .popup-content .eu-cookie-compliance-categories {
      min-width: 280px; }
    #sliding-popup .popup-content .eu-cookie-compliance-category {
      margin-top: 0.65em;
      margin-bottom: 0.65em; }
      #sliding-popup .popup-content .eu-cookie-compliance-category__option {
        display: flex;
        align-items: start; }
        #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"] {
          flex: 0 0 auto;
          margin: 0 0.15rem 0 0 !important; }
          #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"][disabled] {
            opacity: 0.75; }
          #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"]:checked:not([disabled]) {
            filter: #5cb85c; }
            #sliding-popup .popup-content .eu-cookie-compliance-category__option input[type="checkbox"]:checked:not([disabled]) + label {
              color: #5cb85c; }
        #sliding-popup .popup-content .eu-cookie-compliance-category__option label {
          flex: 1 1 auto;
          padding: 0 !important;
          margin: 0 !important;
          line-height: 1.1; }
      #sliding-popup .popup-content .eu-cookie-compliance-category:not(:last-child) {
        margin-bottom: 0.35em; }
    #sliding-popup .popup-content .eu-cookie-compliance-category-description {
      font-size: 12px;
      line-height: 1.15;
      opacity: 0.65; }
    #sliding-popup .popup-content #popup-buttons {
      display: flex; }
      @media only screen and (max-width: 767px) {
        #sliding-popup .popup-content #popup-buttons {
          flex-direction: column;
          flex: 1; } }
      #sliding-popup .popup-content #popup-buttons button {
        margin-bottom: 2px !important;
        white-space: nowrap; }
    #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons {
      display: block; }
      #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button {
        display: block;
        width: 100% !important;
        margin-top: 0.2em !important;
        margin-bottom: 0.2em !important;
        text-align: center !important;
        font-size: 14px !important; }
        #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button:not(.agree-button) {
          padding-top: 0.35em !important;
          padding-bottom: 0.35em !important; }
        #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button.agree-button {
          font-weight: 600 !important; }
          #sliding-popup .popup-content .eu-cookie-compliance-categories + #popup-buttons .button.agree-button:hover {
            background-color: #fff !important;
            color: #222 !important; }
  #sliding-popup .eu-cookie-withdraw-tab {
    font-size: 12px;
    padding: 0.75em;
    text-align: center;
    text-align: center;
    display: block;
    width: 100%;
    left: 0;
    top: 0;
    transform: translate(0, -100%); }
    @media (min-width: 544px) {
      #sliding-popup .eu-cookie-withdraw-tab {
        left: 35px;
        width: auto;
        display: inline; } }
  #sliding-popup.eu-cookie-withdraw-wrapper {
    padding-top: 0;
    padding-bottom: 0; }
    #sliding-popup.eu-cookie-withdraw-wrapper .eu-cookie-withdraw-banner {
      background: none;
      padding-top: 15px;
      padding-bottom: 15px; }
    #sliding-popup.eu-cookie-withdraw-wrapper .popup-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap; }
      #sliding-popup.eu-cookie-withdraw-wrapper .popup-content > * {
        padding-left: 1rem;
        padding-right: 1rem; }
  #sliding-popup .eu-cookie-compliance-hidden {
    display: none !important; }

.block-euccx-euccx-cookie-settings {
  position: relative; }
  .block-euccx-euccx-cookie-settings #cookie-tabs {
    display: flex;
    flex-direction: column;
    padding: 0; }
    @media screen and (min-width: 700px) {
      .block-euccx-euccx-cookie-settings #cookie-tabs {
        flex-direction: row; } }
    .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav {
      width: auto;
      flex: 0 0 30%;
      margin: 0;
      padding-bottom: 3px;
      background: WhiteSmoke; }
      @media screen and (min-width: 700px) {
        .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav {
          padding-bottom: 0; } }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav > li > a {
        width: 100%;
        border: 0 none;
        background-color: WhiteSmoke;
        color: #222;
        margin-bottom: 0 !important; }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav > li.ui-state-active > a {
        background-color: #fff;
        font-weight: bold; }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-nav::after {
        display: none; }
    .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-panel {
      position: relative;
      width: auto;
      flex: 0 0 70%;
      padding: 40px 2rem 2rem 2rem; }
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-panel h2,
      .block-euccx-euccx-cookie-settings #cookie-tabs .ui-tabs-panel h3 {
        font-weight: bold;
        margin-bottom: 0.5em; }

body:not(.eu-cookie-compliance-status-null):not(.eu-cookie-compliance-popup-open) {
  overflow: hidden; }
  body:not(.eu-cookie-compliance-status-null):not(.eu-cookie-compliance-popup-open) #sliding-popup {
    position: relative; }
  body:not(.eu-cookie-compliance-status-null):not(.eu-cookie-compliance-popup-open) .eu-cookie-withdraw-tab {
    background-color: #888888;
    border-color: #888888; }

body.eu-cookie-compliance-popup-open.eu-cookie-compliance-status-null {
  padding-bottom: 300px !important; }

body:not([class*="eu-cookie-compliance-status-"]) {
  padding-bottom: 300px !important; }
  body:not([class*="eu-cookie-compliance-status-"]) #sliding-popup {
    position: fixed !important; }

/* ================================================================== */
/* EUCC Button QUICKFIX */
/* === Fix new law requirements: Equaly sized and colored buttons === */
body #sliding-popup #popup-buttons .button,
body #sliding-popup #popup-buttons .button.agree-button,
body #sliding-popup #popup-buttons .button:not(.agree-button),
body
#sliding-popup
.popup-content
.eu-cookie-compliance-categories
+ #popup-buttons
.button:not(.agree-button) {
  font-weight: bold !important;
  background: #26509c !important;
  color: White !important;
  padding: 10px 18px !important; }

body #sliding-popup #popup-buttons .button:hover,
body #sliding-popup #popup-buttons .button.agree-button:hover,
body #sliding-popup #popup-buttons .button:not(.agree-button):hover,
body
#sliding-popup
.popup-content
.eu-cookie-compliance-categories
+ #popup-buttons
.button:not(.agree-button):hover,
body
#sliding-popup
.popup-content
.eu-cookie-compliance-categories
+ #popup-buttons
.button.agree-button:hover {
  background: #26509c !important;
  color: White !important;
  filter: brightness(1.2); }

.embedded-video {
  margin-bottom: 1rem; }
  .embedded-video + .video-embed-mytube-description {
    margin-top: -0.6rem;
    margin-bottom: 1rem; }

.embedded-video .player,
div:not(.player) > .mytube-wrapper {
  position: relative;
  padding-bottom: 51.5%;
  padding-top: 25px;
  height: 0;
  box-sizing: border-box; }
  .embedded-video .player *,
  div:not(.player) > .mytube-wrapper * {
    box-sizing: border-box; }
  .embedded-video .player iframe,
  .embedded-video .player .mytube,
  div:not(.player) > .mytube-wrapper iframe,
  div:not(.player) > .mytube-wrapper .mytube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }
  .embedded-video .player .mytube,
  div:not(.player) > .mytube-wrapper .mytube {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .embedded-video .player .mytubethumb,
  div:not(.player) > .mytube-wrapper .mytubethumb {
    object-fit: cover;
    width: 100% !important;
    height: 100% !important;
    max-width: none;
    margin: 0 !important; }
  .embedded-video .player .mytubeplay,
  div:not(.player) > .mytube-wrapper .mytubeplay {
    display: none !important; }
  .embedded-video .player .mytubetrigger,
  div:not(.player) > .mytube-wrapper .mytubetrigger {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%; }
    .embedded-video .player .mytubetrigger + iframe + .mytubetext,
    div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext {
      opacity: .5;
      border-radius: 0 0 0 3px;
      padding: .35em .5em;
      margin: 0 0 auto 0;
      align-self: flex-end;
      font-size: 10px;
      line-height: 1.5;
      max-width: 50%;
      max-height: 2.2em;
      overflow: hidden;
      text-align: right;
      hyphens: auto;
      word-break: break-all;
      background: rgba(255, 255, 255, 0.6); }
      .embedded-video .player .mytubetrigger + iframe + .mytubetext::before, .embedded-video .player .mytubetrigger + iframe + .mytubetext::after,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext::before,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext::after {
        display: none !important; }
      .embedded-video .player .mytubetrigger + iframe + .mytubetext:focus-within, .embedded-video .player .mytubetrigger + iframe + .mytubetext:hover,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext:focus-within,
      div:not(.player) > .mytube-wrapper .mytubetrigger + iframe + .mytubetext:hover {
        max-width: 95%;
        max-height: 3.6em;
        line-height: 1.1;
        word-break: normal; }
  .embedded-video .player .mytubetext,
  div:not(.player) > .mytube-wrapper .mytubetext {
    display: block;
    position: relative;
    z-index: 1;
    align-self: center;
    justify-content: center;
    margin: 92px 0 0 0;
    max-width: 90%;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.8);
    padding: .65em;
    line-height: 1.1;
    font-size: 14px;
    border-radius: 3px;
    transition-duration: 150ms;
    transition-property: padding, background-color, bottom, max-width, max-height; }
    .embedded-video .player .mytubetext::before, .embedded-video .player .mytubetext::after,
    div:not(.player) > .mytube-wrapper .mytubetext::before,
    div:not(.player) > .mytube-wrapper .mytubetext::after {
      content: "";
      position: absolute;
      left: 50%;
      top: -92px;
      z-index: 3;
      margin-left: -37px;
      box-sizing: border-box;
      width: 64px;
      height: 74px;
      border-width: 37px 0px 37px 64px;
      border-style: solid;
      border-color: transparent transparent transparent rgba(255, 255, 255, 0.85);
      pointer-events: none; }
    .embedded-video .player .mytubetext:after,
    div:not(.player) > .mytube-wrapper .mytubetext:after {
      z-index: 2;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.35);
      filter: blur(3px); }
    .embedded-video .player .mytubetext:hover,
    div:not(.player) > .mytube-wrapper .mytubetext:hover {
      opacity: 1 !important;
      background: rgba(255, 255, 255, 0.9); }
      .embedded-video .player .mytubetext:hover::before,
      div:not(.player) > .mytube-wrapper .mytubetext:hover::before {
        border-color: transparent transparent transparent #fff; }
  .embedded-video .player .mytubeplay,
  div:not(.player) > .mytube-wrapper .mytubeplay {
    top: 50% !important;
    left: 50% !important;
    margin-left: -32px;
    margin-top: -30px; }
  .embedded-video .player:focus-within .mytubetext::before, .embedded-video .player:hover .mytubetext::before,
  div:not(.player) > .mytube-wrapper:focus-within .mytubetext::before,
  div:not(.player) > .mytube-wrapper:hover .mytubetext::before {
    border-color: transparent transparent transparent #fff !important; }
